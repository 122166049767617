import React from 'react';
import './App.css';
import Home from './Home'
import States from './States'
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from '../theme';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import NavBar from './NavBar';
import RepDetail from './RepDetail';

export default function App() {
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Router>
        <NavBar/>
            <Switch>
              <Route path="/" exact component={Home}/>
              <Route path="/states" exact component={States}/>
              <Route path='/info' render={(props) => <RepDetail {...props} />}/>
            </Switch>
        </Router>
      </MuiThemeProvider>
    </div>
  );
}
