import React, {Component} from "react";
import { connect } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import SearchIcon from '@material-ui/icons/Search';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Chip from '@material-ui/core/Chip';
import Hidden from '@material-ui/core/Hidden';
import { classnames } from '../helpers';

import PlacesAutocomplete from "react-places-autocomplete";

import { Redirect } from 'react-router-dom'

import { initReps, getReps } from "../actions/reps";
import RepCard from "./RepCard";
import { withRouter } from 'react-router-dom'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Democracy Rises
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const google = window.google;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      {}
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  }

  useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    demo_search_bar_container: {
      width: "90%",
      maxWidth: "500px",
      margin: "40px auto 0",
    },
    location_search_input: {
      boxShadow: "0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08)",
      border: "honeydew",
      display: "block",
      width: "100%",
      padding: "16px",
      fontSize: "16px",
      borderRadius: "2px",
      outline: "none"
    },
    aboutSection: {
      minHeight: '100vh',
      position: 'relative',
      backgroundColor: '#DCDCDC',
      zIndex: 1,
      '&:before': {
        backgroundColor: 'inherit',
        top: 0,
        content: '',
        display: 'block',
        height: '50%',
        left: 0,
        position: 'absolute',
        right: 0,
        transform: 'skewY(-1.5deg)',
        transformOrigin: '100%',
        zIndex: -1,
      }
      // clipPath: 'polygon(0 0, 0% 0, 100% 96%, 0 100%)'
    },
    videoTag: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0
    }
  }));

  initialState() {
    return {
      showDetails: false,
      selectedOfficial: {},
      selectedOffice: {},
      address: "",
      name: "",
      street_address: "",
      city: "",
      state: "",
      zip_code: "",
      googleMapLink: "",
      reps: {},
      error: false,
    };
  }

  handleChange = (address) => {
    this.setState({ 
      address: address,
      error: false
     });
  };

  submitForm = () => {
    const { getReps } = this.props;
    const { address } = this.state;
    getReps(address);
  };

  handleSelect = (address) => {
    this.setState({ address });
  };

  componentDidUpdate() {
    console.log(this.props.reps);
  }

  viewRepDetails = (official, office) => {
    this.setState({
      selectedOffice: office,
      selectedOfficial: official,
    })
    this.props.history.push({
      pathname: "/info",
      state: { 
        office: office,
        official: official
      }
    })
  }



  renderReps = () => {
    if (Object.keys(this.props.reps.reps).length !== 0) {
      let officials = this.props.reps.reps.officials
      let offices = this.props.reps.reps.offices
      let office = {}
      return (
        officials.map((official, index) => {
          for (let i=0; i<offices.length; i++) {
            if (offices[i].officialIndices.includes(index)) {
              office = offices[i]
            }
          }
          return (
            <Grid container item xs={12} sm={12} md={4} lg={3} justify="center">
                <RepCard
                  official={official}
                  office={office}
                  selectRep={this.viewRepDetails}
                />
            </Grid>
          )
        })
      )
    }
  };

  handleClick = () => {
    const { initReps } = this.props;
    initReps()
  }

  render() {
    const { address, selectedOffice, selectedOfficial } = this.state;
    const submit = this.submitForm;
    const renderReps = this.renderReps;
    const handleClick = this.handleClick;

    if (this.state.showDetails) {
      return (
        <Redirect 
        push 
        from='/'
        to={{
          pathname: "/info",
          state: { 
            office: selectedOffice,
            official: selectedOfficial
          }
        }}
      />
      )
      
    }

    if (Object.keys(this.props.reps.reps).length === 0) {
      return  (
        <>
        <Container maxWidth={'sm'} component="main">
        <CssBaseline />
          <div
            style={{
              marginTop: 80,
              padding: 20,
              borderRadius: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#DCDCDC",
            }}
          >
            <Avatar style={{ backgroundColor: '#474b4f', justifyContent: "center", alignItems: "center" }}>
              <SearchIcon />
            </Avatar>
            <Typography component="h1" variant="h5" style={{ marginTop: 20 }}>
              Write Your Representatives
            </Typography>
            <form style={{ width: "100%", marginTop: 30 }} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  {(this.props.reps.error) !== "" && 
                    <Typography color="error" style={{paddingBottom: 10}}>Please double check the address you entered and try again</Typography>
                  }
                <PlacesAutocomplete
                  onChange={this.handleChange}
                  value={address}
                  onSelect={this.handleSelect}
                  onError={this.handleError}
                  shouldFetchSuggestions={address.length > 2}
                >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className="Demo__search-bar-container">
                <div className="Demo__search-input-container">
                  <input
                    {...getInputProps({
                      placeholder: 'Enter your address...',
                      className: 'Demo__search-input',
                    })}
                  />
                  {this.state.address.length > 0 && (
                    <button
                      className="Demo__clear-button"
                      onClick={this.handleCloseClick}
                    >
                      x
                    </button>
                  )}
                </div>
                {suggestions.length > 0 && (
                  <div className="Demo__autocomplete-container">
                    {suggestions.map(suggestion => {
                      const className = classnames('Demo__suggestion-item', {
                        'Demo__suggestion-item--active': suggestion.active,
                      });

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{' '}
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                    })}
                    <div className="Demo__dropdown-footer">
                      <div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>
                </Grid>
              </Grid>
              {address !== "" && (
                <Button
                  fullWidth
                  variant="contained"
                  style={{ color: '#ffffff', backgroundColor: '#465078', marginTop: 30 }}
                  onClick={() => {
                    submit();
                  }}
                >
                  Contact
                </Button>
              )}
            </form>
          </div>
      </Container>
      <div 
        style={{
          marginTop: '8%',
          paddingBottom: 130,
          color: '#ffffff',
          backgroundColor: '#10273C',
          clipPath: 'polygon(0 45px, 100% 0, 100% 100%, 0 100%)'}}
        >
        <Container maxWidth={'lg'} style={{marginTop: 100, paddingTop: 100}}>
          <Typography component='h2' variant='h4'>How To Use</Typography>
          <Grid container spacing={3} style={{marginTop: 50}}>
            <Grid item xs={12} md={4}>
              <div>
                <img style={{width: 80, height: 80, objectFit: 'cover'}} src={require("../assets/addressHome.svg")} alt="Enter your address"/>
                <Typography component='h3' variant='h6'>Enter your address</Typography>
                <p>Based on your location, different individuals represent you on a local, state, and federal level.</p>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
            <div>
              <img style={{width: 80, height: 80, objectFit: 'cover'}} src={require("../assets/view.svg")} alt="View your representatives"/>
              <Typography component='h3' variant='h6'>View your representatives</Typography>
              <p>We'll show you results from the top down, starting with the President of the United States.</p>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
            <div>
              <img style={{width: 80, height: 80, objectFit: 'cover'}} src={require("../assets/send.svg")} alt="Get in touch"/>
              <Typography component='h3' variant='h6'>Get in touch</Typography>
              <p>We'll show you all the contact options available for your selected representative.</p>
              </div>
            </Grid>
          </Grid>
        </Container>         
      </div>
      </>
      )
    } else {
      return (
        <Container maxWidth={'md'} style={{minHeight:'100vh'}} component="main">
          <Chip
            style={{position: 'absolute', top: 70, left: 10}}
            color="primary"
            avatar={<Avatar style={{backgroundColor: 'transparent'}} alt="Go Back" src={require('../assets/back.svg')} />}
            label='Back' onClick={() => handleClick()}
          />
          <CssBaseline />
          {address !== "" && (
            <div style={{marginTop: 50, justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
              <Hidden smDown>
                <img style={{width: 40, height: 40, objectFit: 'cover', marginRight: 10}} alt="address" src={require('../assets/address.svg')}/>
              </Hidden>
              <Typography component="h4" variant="h4">{this.state.address}</Typography>
            </div>
          )}

          
            <Grid style={{marginTop: 30}} container spacing={3}>
            {renderReps()}
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  reps: state.reps,
});

export default withRouter(connect(mapStateToProps, {
  getReps,
  initReps
})(Home));
