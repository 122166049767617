import {
    INIT_REPS,
    GET_REPS_SUCCESS,
    GET_REPS_FAILURE,
  } from '../constants/reps';

  const API_URL = 'https://www.googleapis.com/civicinfo/v2/representatives?key=AIzaSyDaaZ6UVEl3Haaxx0RqB92mfvWrkZ7pM5w&';

  export function clearReps() {
    return { type: INIT_REPS };
  };

  export function getRepsSuccess(details) {
    return {
      type: GET_REPS_SUCCESS,
      details,
    };
  }
  
  export function getRepsFailure(details) {
    return {
      type: GET_REPS_FAILURE,
      details,
    };
  }

  export const initReps = () => dispatch => {
    dispatch(clearReps())
  }

  export const getReps = address => dispatch => {
      console.log("GET REPS")
    return fetch(`${API_URL}address=${address}`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error("Please check the address you entered and try again")
          }
          return res.json()
        })
        .then(responseJson => {
          console.log(responseJson)
            dispatch(getRepsSuccess(responseJson));
        })
        .catch(e => {
          console.log('status', e)
          dispatch(getRepsFailure(e));
        });
  }