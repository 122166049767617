import {
    INIT_REPS,
    GET_REPS_SUCCESS,
    GET_REPS_FAILURE
  } from '../constants/reps';
  
  const initialState = {
    reps: {},
    error: ''
  };
  
  export default function OrderReducer(state = initialState, action) {
    
    switch (action.type) {
      case INIT_REPS:
        console.log("initialState")
        return {...initialState}
      case GET_REPS_SUCCESS:
        return {
            ...state,
            reps: action.details,
            error: ''
            };
      case GET_REPS_FAILURE:
        console.log("ERROR GETTING REPS")
        return {
            ...state,
            reps: {},
            error: 'error getting reps',
          };
      default:
        return {...initialState}
      }
  }
  