import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Avatar from "@material-ui/core/Avatar";
import Chip from '@material-ui/core/Chip';
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#EEEEEE',
    overflow: 'hidden',
    position: 'relative'
  },
  media: {
    height: 180,
    width: 150,
    borderRadius: 6,
    objectFit: 'cover'
  },
  iconDem: {
      height: 26,
      width: 32,
      display: 'inline-block',
      objectFit: "fit"
  },
  iconRepublican: {
    height: 18,
    width: 26,
    display: 'inline-block',
    objectFit: "fit"
},
  heading: {
    fontSize: '16px',
    fontWeight: 600,
    paddingTop: 10
  },
  subHeader: {
    fontSize: '14px',
    fontWeight: 400,
    paddingLeft: 10,
    paddingRight: 10
  },
  buttonClass: {
    width: 230,
    height: 50,
    backgroundColor: '#dcdcdc',
    position: 'absolute',                  
    bottom: 0,                 
    left: 0
    },
    contactContainer: {
        width: '100%',
        textAlign: 'left',
        paddingLeft: 15,
        paddingRight: 15
    }
}));


const renderParty = official => {
    if (official.party === "Republican Party") {
        return (
            <div style={{display: 'inline-block', float: 'left', paddingTop: 10, paddingLeft: 15}}>
                 <img style={{display: 'inline-block', height: 18, width: 26, verticalAlign: 'middle'}} src={require('../assets/republican.png')} alt={"republican party"}/>
                 <Typography style={{display: 'inline-block', paddingLeft: 15, verticalAlign: 'middle'}}>Republican Party</Typography>
            </div>
        )
    } else if (official.party === "Democratic Party") {
        return (
            <div style={{display: 'inline-block', float: 'left', paddingTop: 10, paddingLeft: 15}}>
                 <img style={{display: 'inline-block', height: 26, width: 32, verticalAlign: 'middle'}} src={require('../assets/democrat.png')} alt={"republican party"}/>
                 <Typography style={{display: 'inline-block', paddingLeft: 15, verticalAlign: 'middle'}}>Democratic Party</Typography>
            </div>
        )
    } else if (official.party === "Independent") {
      return (
          <div style={{display: 'inline-block', float: 'left', paddingTop: 10, paddingLeft: 15}}>
               <img style={{display: 'inline-block', height: 26, width: 32, verticalAlign: 'middle'}} src={require('../assets/independent.png')} alt={"independent party"}/>
               <Typography style={{display: 'inline-block', paddingLeft: 15, verticalAlign: 'middle'}}>Independent</Typography>
          </div>
      )
    } else if (official.party === "Unaffiliated") {
      return (
          <div style={{display: 'inline-block', float: 'left', paddingTop: 10, paddingLeft: 15}}>
               <img style={{display: 'inline-block', height: 26, width: 32, verticalAlign: 'middle'}} src={require('../assets/independent.png')} alt={"independent party"}/>
               <Typography style={{display: 'inline-block', paddingLeft: 15, verticalAlign: 'middle'}}>Unaffiliated</Typography>
          </div>
      )
    } else {
          return
      }
}

const renderInfo = office => {
    switch(office.name.toLowerCase()) {
        case "president of the united states":
            return (<p><span><b>About</b></span><br/>The president of the United States (POTUS) is the head of state and head of government of the United States of America. The president directs the executive branch of the federal government and is the commander-in-chief of the United States Armed Forces.

<br/><br/>In contemporary times, the president is looked upon as one of the world's most powerful political figures as the leader of the only remaining global superpower. The role includes responsibility for the world's most expensive military, which has the second largest nuclear arsenal. The president also leads the nation with the largest economy by nominal GDP. The president possesses significant domestic and international hard and soft power.
        </p>)
        case "vice president of the united states":
            return (<p><span><b>About</b></span><br/>The vice president of the United States is the second-highest officer in the executive branch of the U.S. federal government, after the president of the United States, and ranks first in the presidential line of succession. The vice president is also an officer in the legislative branch, as president of the Senate. In this capacity, the vice president is empowered to preside over Senate deliberations, but may not vote except to cast a tie-breaking vote. The vice president also presides over joint sessions of Congress.

                The vice president is indirectly elected together with the president to a four-year term of office by the people of the United States through the Electoral College. Section 2 of the Twenty-fifth Amendment, ratified in 1967, created a mechanism for intra-term vice presidential succession, establishing that vice presidential vacancies will be filled by the president and confirmed by both houses of Congress. Prior to 1967, in the event a vice president succeeded to the presidency, died, or resigned from office, the vice presidency remained vacant until the next presidential and vice presidential terms began.
                
                The vice president is also a statutory member of the National Security Council, and the Board of Regents of the Smithsonian Institution. The Office of the Vice President assists and organises the vice president's official functions. The role of the vice presidency has changed dramatically since the office was created during the 1787 constitutional Convention. Especially over the past 100 years, the vice presidency has evolved into a position of domestic and foreign policy political power, and is now widely seen as an integral part of a president's administration.
                
                As the vice president's role within the executive branch has expanded, their role within the legislative branch has contracted; for example, they preside over the Senate only infrequently.
                
                The Constitution does not expressly assign the vice presidency to any one branch, causing a dispute among scholars about which branch of government the office belongs to: 1) the executive branch; 2) the legislative branch; 3) both; or 4) neither. The modern view of the vice president as an officer of the executive branch (isolated almost totally from the legislative branch) is due in large part to the assignment of executive authority to the vice president by either the president or Congress.</p>)
        case "u.s. senator":
            return (<p><span><b>About</b></span><br/>The United States Senate is the upper chamber of the United States Congress, which, along with the United States House of Representatives—the lower chamber—constitutes the legislature of the United States. The Senate chamber is located in the north wing of the Capitol Building in Washington, D.C.

                The composition and powers of the Senate are established by Article One of the United States Constitution. The Senate is composed of senators, each of whom represents a single state in its entirety. Each state, regardless of its population size, is equally represented by two senators who serve staggered terms of six years. There being at present 50 states in the Union, there are currently 100 senators. From 1789 to 1913, senators were appointed by legislatures of the states they represented; they are now elected by popular vote, following the ratification of the Seventeenth Amendment in 1913.
                
                As the upper chamber of Congress, the Senate has several powers of advice and consent which are unique to it. These include the approval of treaties, and the confirmation of Cabinet secretaries, Supreme Court justices, federal judges, flag officers, regulatory officials, ambassadors, other federal executive officials and other federal uniformed officers. In addition to these, in cases wherein no candidate receives a majority of electors for vice president, the duty falls to the Senate to elect one of the top two recipients of electors for that office. Furthermore, the Senate has the responsibility of conducting the trials of those impeached by the House.
                
                The Senate is widely considered both a more deliberative and more prestigious body than the House of Representatives due to its longer terms, smaller size, and statewide constituencies, which historically led to a more collegial and less partisan atmosphere. The presiding officer of the Senate is the vice president of the United States, who is president of the Senate. In the vice president's absence, the president pro tempore, who is customarily the senior member of the party holding a majority of seats, presides over the Senate. In the early 20th century, the practice of majority and minority parties electing their floor leaders began, although they are not constitutional officers.</p>)
        case "u.s. representative":
            return (<p><span><b>About</b></span><br/>The United States House of Representatives is the lower house of the United States Congress, the Senate being the upper house. Together they compose the national bicameral legislature of the United States.

                The composition of the House is established by Article One of the United States Constitution. The House is composed of representatives who sit in congressional districts that are allocated to each of the 50 states on a basis of population as measured by the U.S. Census, with each district entitled to one representative. Since its inception in 1789, all representatives have been directly elected. The total number of voting representatives is fixed by law at 435. In addition, there are currently six non-voting members, bringing the total membership of the US House of Representatives to 441 or fewer in the case of vacancies. As of the 2010 Census, the largest delegation is that of California, with 53 representatives. Seven states have only one representative: Alaska, Delaware, Montana, North Dakota, South Dakota, Vermont, and Wyoming.
                
                The House is charged with the passage of federal legislation, known as bills, which, after concurrence by the Senate, are sent to the president for consideration. In addition to this basic power, the House has certain exclusive powers, among them the power to initiate all bills related to revenue; the impeachment of federal officers, who are sent to trial before the Senate; and, in cases wherein no candidate receives a majority of electors for president, the duty falls upon the House to elect one of the top three recipients of electors for that office, with one vote given to each state for that purpose. The House meets in the south wing of the United States Capitol.
                
                The presiding officer is the speaker of the House, who is elected by the members thereof (and is therefore traditionally the leader of the controlling party). The speaker and other floor leaders are chosen by the Democratic Caucus or the Republican Conference, depending on whichever party has more voting members.</p>)
        default:
          if (office.name.toLowerCase().includes("lieutenant governor of")) {
              return (<p><span><b>About</b></span><br/>In the United States, 45 of the 50 states have an office of lieutenant governor. In two of the 45 states, the speaker of the upper house of the state legislature serves in such a capacity. In most cases, the lieutenant governor is the highest officer of state after the governor, standing in for that officer when they are absent from the state or temporarily incapacitated. In the event a governor dies, resigns or is removed from office, the lieutenant governor typically becomes governor.

                In 26 states, the governor and lieutenant governor are elected on the same ticket, ensuring that they come from the same political party. In the remaining 18 states, they are elected separately and, thus, may come from different parties. The lieutenant governor is also frequently the presiding officer of the upper house of the state legislature, similar to the Vice President of the United States. Among the seven states without a separate, full-time office of lieutenant governor, two states have a post of lieutenant governor that is filled by the highest officer of the State Senate. In Tennessee, the full title of the leader of the Senate is "lieutenant governor and speaker of the Senate". In West Virginia, the title of lieutenant governor is assigned by statute to the Senate President. With the exception of Arkansas, Georgia, Tennessee, Texas, Virginia, Washington and West Virginia, every state has had a female lieutenant governor or equivalent.
                
                In Maine, the presiding officer of the State Senate assumes the governor's office upon a vacancy, while in New Hampshire, the presiding officer of the State Senate assumes only the governor's powers and duties (becomes acting governor) upon a vacancy. In the remaining three states – Arizona, Oregon and Wyoming – the Secretary of State becomes governor upon the office's vacancy. The U.S. territories of American Samoa, Guam, Northern Mariana Islands and the Virgin Islands have the office of lieutenant governor. In Puerto Rico, the appointed Secretary of State becomes governor upon the office's vacancy while the Chief of Staff is typically the highest office after the governor.
                
                Lieutenant governors are the only officials with specific duties and powers in two branches of state government: the executive and legislative branches. More than half of the NLGA members preside over their state senate. Most pursue legislative initiatives; many testify locally and in Washington D.C. in various capacities; some serve on the governors’ cabinets, while others maintain varied portfolios of duties. In many states, the duties of lieutenant governor are increased by legislation to include the lieutenant governor on state boards, commissions and task forces."</p>)
          } else if (office.name.toLowerCase().includes("governor of")) {
            return (<p><span><b>About</b></span><br/>In the United States, a governor serves as the chief executive officer and commander-in-chief in each of the fifty states and in the five permanently inhabited territories, functioning as both head of state and head of government therein.[nb 1] As such, governors are responsible for implementing state laws and overseeing the operation of the state executive branch. As state leaders, governors advance and pursue new and revised policies and programs using a variety of tools, among them executive orders, executive budgets, and legislative proposals and vetoes. Governors carry out their management and leadership responsibilities and objectives with the support and assistance of department and agency heads, many of whom they are empowered to appoint. A majority of governors have the authority to appoint state court judges as well, in most cases from a list of names submitted by a nominations committee.

                All but five states (Arizona, Maine, New Hampshire, Oregon, and Wyoming) have a lieutenant governor. The lieutenant governor succeeds to the gubernatorial office (the powers and duties but not the office, in Massachusetts and West Virginia), if vacated by the removal from office, death, or resignation of the previous governor. Lieutenant governors also serve as unofficial acting state governors in case the incumbent governors are unable to fulfill their duties, and they often serve as presiding officers of the upper houses of state legislatures. But in such cases, they cannot participate in political debates, and they have no vote whenever these houses are not equally divided.</p>)
          } else if (office.name.toLowerCase().includes("state representative")) {
            return (<p><span><b>About</b></span><br/>Every state except Nebraska has a bicameral legislature, meaning that the legislature consists of two separate legislative chambers or houses. In each case the smaller chamber is called the Senate and is usually referred to as the upper house. This chamber typically, but not always, has the exclusive power to confirm appointments made by the governor and to try articles of impeachment. (In a few states, a separate Executive Council, composed of members elected from large districts, performs the confirmation function.) Members of the smaller chamber represent more citizens and usually serve for longer terms than members of the larger chamber, generally four years. In 41 states, the larger chamber is called the House of Representatives. Five states designate the larger chamber the Assembly and three states call it the House of Delegates. Members of the larger chamber usually serve for terms of two years. The larger chamber customarily has the exclusive power to initiate taxing legislation and articles of impeachment.</p>)
          } else if (office.name.toLowerCase().includes("state senator")) {
            return (<p><span><b>About</b></span><br/>Every state except Nebraska has a bicameral legislature, meaning that the legislature consists of two separate legislative chambers or houses. In each case the smaller chamber is called the Senate and is usually referred to as the upper house. This chamber typically, but not always, has the exclusive power to confirm appointments made by the governor and to try articles of impeachment. (In a few states, a separate Executive Council, composed of members elected from large districts, performs the confirmation function.) Members of the smaller chamber represent more citizens and usually serve for longer terms than members of the larger chamber, generally four years. In 41 states, the larger chamber is called the House of Representatives. Five states designate the larger chamber the Assembly and three states call it the House of Delegates. Members of the larger chamber usually serve for terms of two years. The larger chamber customarily has the exclusive power to initiate taxing legislation and articles of impeachment.</p>)
          } else if (office.name.toLowerCase().includes("sheriff")) {
            return (<p><span><b>About</b></span><br/>The office of sheriff as county official in colonial North America is recorded from the 1660s. In the modern United States, the scope of a sheriff varies across states and counties (which in Louisiana are called "parishes" and in Alaska "boroughs"). The sheriff is most often an elected county official who serves as the chief civil-law enforcement officer of their jurisdiction. The sheriff enforces court orders and mandates and may perform duties such as evictions, seizing property and assets pursuant to court orders, and serving warrants and legal papers. In some counties where urban areas have their own police departments, a sheriff may be restricted to civil procedure enforcement duties, while in other counties, the sheriff may serve as the principal police force and have jurisdiction over all of the county's municipalities, regardless if they have their own city or town/township police department. A sheriff often administers the county jails and is responsible for court security functions within their jurisdiction.</p>)
          } else if (office.name.toLowerCase().includes("mayor")) {
            return (<p><span><b>About</b></span><br/>The mayor is the municipal head of government, the maximum civil authority at the municipal level, in most United States municipalities (such as cities, townships, etc.). In the United States, there are several distinct types of mayors, depending on whether the system of local government is council-manager government or mayor-council government.

            Under the council-manager government system, the mayor is a first among equals on the city council, which acts as a legislative body while executive functions are performed by the appointed manager. The mayor may chair the city council, but lacks any special legislative powers. The mayor and city council serve part-time, with day-to-day administration in the hands of a professional city manager. The system is most common among medium-sized cities from around 25,000 to several hundred thousand, usually rural and suburban municipalities.</p>)
          } else {
            return (<p><span><b>About</b></span><br/>Info coming soon</p>)
          }
      }
}



const RepDetail = (props) => {
  let { official, office } = props.location.state;
  if (office === undefined) {
    office = {}
  }

  const handleClick = () => {
    console.log("GO BACK")
    props.history.goBack()
  }

  const classes = useStyles();

  const renderPlaceholder = () => {
    if (official.party === "Republican Party") {
      return <img className={classes.media} src={require('../assets/repPlaceholder.svg')} alt={official.name}/>
    } else if (official.party === "Democratic Party") {
      return <img className={classes.media} src={require('../assets/demPlaceholder.svg')} alt={official.name}/>
    } else {
      return <img className={classes.media} src={require('../assets/indPlaceholder.svg')} alt={official.name}/>
    }
  }

  return (
    <Container maxWidth={'md'} style={{minHeight:'100vh'}} component="main">
        <Chip
            style={{position: 'absolute', top: 70, left: 10}}
            color="primary"
            avatar={<Avatar style={{backgroundColor: 'transparent'}} alt="Go Back" src={require('../assets/back.svg')} />}
            label='Back' onClick={() => handleClick()}
        />
        <CssBaseline />
        <div className={classes.root}>
        <Grid container spacing ={3} style={{marginTop: 20}} alignItems={"flex-start"}>
            <Grid item xs={12} md={3}>
            {official.hasOwnProperty("photoUrl") ?
                <img className={classes.media} src={official.photoUrl} alt={official.name}/> :
                renderPlaceholder()
            }
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography style={{paddingLeft: 15}} align="left" variant="h3" component="h1">{official.name}</Typography>
                <Typography style={{paddingLeft: 15}} align="left" variant="h4" component="h2">{office.name}</Typography>
                {renderParty(official)}
            </Grid>
            <Grid item xs={12} md={4}>
                <div style={{display: 'flex', paddingLeft: 15, width: '100%'}}>
                    <p><b>Contact</b></p>
                </div>
                {official.hasOwnProperty("phones") &&
                    <div style={{display: 'flex', paddingLeft: 15, width: '100%'}}>
                        <p style={{alignSelf: 'flex-start', textAlign: 'left'}}><img style={{height: 20, width: 20, float: 'left', marginRight: 20, objectFit: 'contain'}} src={require('../assets/phone.svg')} alt={"phone"}/></p>
                        <p style={{textAlign: 'left'}}><a href={"tel:" + official.phones[0]}>{official.phones[0]}</a></p>
                    </div>
                }
                {official.hasOwnProperty("urls") &&
                    <div style={{display: 'flex', paddingLeft: 15, width: '100%'}}>
                    <p style={{alignSelf: 'flex-start', textAlign: 'left'}}><img style={{height: 20, width: 20, float: 'left', marginRight: 20, objectFit: 'contain'}} src={require('../assets/website.svg')} alt={"website"}/></p>
                    <p style={{textAlign: 'left'}}><a href={official.urls[0]}>Website</a></p>
                </div>
                }
                {official.hasOwnProperty("emails") &&
                    <div style={{display: 'flex', paddingLeft: 15, width: '100%'}}>
                        <p style={{alignSelf: 'flex-start', textAlign: 'left'}}><img style={{height: 20, width: 20, float: 'left', marginRight: 20, objectFit: 'contain'}} src={require('../assets/email.svg')} alt={"email"}/></p>
                        <p style={{textAlign: 'left'}}><a href={"mailTo:" + official.emails[0]}>{official.emails[0]}</a></p>
                    </div>
                }
                {official.hasOwnProperty("address") &&
                    <div style={{display: 'flex', paddingLeft: 15, width: '100%'}}>
                        <p style={{alignSelf: 'flex-start', textAlign: 'left'}}><img style={{height: 20, width: 20, float: 'left', marginRight: 20, objectFit: 'contain'}} src={require('../assets/address.svg')} alt={"address"}/></p>
                        <p style={{textAlign: 'left'}}>{official.address[0].line1}, {official.address[0].city} {official.address[0].state} {official.address[0].zip}</p>
                    </div>
                }
            </Grid>
            <Grid item xs={12} md={8} style={{borderLeft: 'solid', borderWidth: 0.75, borderColor: '#D8D8D8'}} borderLeft={1}>
                <div className={classes.contactContainer}>
                    {renderInfo(office)}
                </div>
            </Grid>
        </Grid>
        </div>
    </Container>
  );
}

export default withRouter(RepDetail)
