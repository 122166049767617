import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 230,
    height: 390,
    backgroundColor: '#EEEEEE',
    borderRadius: 6,
    overflow: 'hidden',
    position: 'relative',
  },
  media: {
    height: 260,
    width: 230,
    objectFit: 'cover'
  },
  heading: {
    fontSize: '16px',
    fontWeight: 400,
    paddingLeft: 15,
    paddingTop: 10
  },
  subHeader: {
    fontSize: '14px',
    fontWeight: 200,
    paddingLeft: 15,
    paddingRight: 10
  },
  buttonClass: {
    width: 230,
    height: 50,
    backgroundColor: '#465078',
    color: '#ffffff',
    borderRadius: 0,
    position: 'absolute',                  
    bottom: 0,                 
    left: 0
    }
}));

const RepCard = (props) => {
  let { official, office, selectRep } = props;

  if (office === undefined) {
    office = {}
  }
  const classes = useStyles();

  // let hovered = false

  // const someHandler = () => {
  //   console.log("ON")
  //   console.log(hovered)
  //   hovered = true
    
  // }


  // const someOtherHandler = () => {
  //   console.log("OFF")
  //   console.log(hovered)
  //   hovered = false
  // }

  const renderPlaceholder = () => {
    if (official.party === "Republican Party") {
      return <img className={classes.media} src={require('../assets/repPlaceholder.svg')} alt={official.name}/>
    } else if (official.party === "Democratic Party") {
      return <img className={classes.media} src={require('../assets/demPlaceholder.svg')} alt={official.name}/>
    } else {
      return <img className={classes.media} src={require('../assets/indPlaceholder.svg')} alt={official.name}/>
    }
  }

  // const renderOverlay = () => {
  //   if (hovered == true) {
  //     console.log("UHHH")
  //     return (<div 
  //       style= {{
  //         position:'absolute',
  //         top: 0,
  //         bottom: 0,
  //         left: 0,
  //         right: 0,
  //         height: '100%',
  //         width: '100%',
  //         opacity: 1,
  //         transition: '.5s ease',
  //         backgroundColor: '#008CBA'}}>
  //         <div>Hello World</div>
  //       </div>)
  //   } else {
  //     return (<div 
  //       style= {{
  //         position:'absolute',
  //         top: 0,
  //         bottom: 0,
  //         left: 0,
  //         right: 0,
  //         height: '100%',
  //         width: '100%',
  //         opacity: 0,
  //         transition: '.5s ease',
  //         backgroundColor: '#008CBA'}}>
  //         <div>Hello World</div>
  //       </div>)
  //   }
  // }

  return (
    <div className={classes.root} 
    // onMouseEnter={someHandler}
    // onMouseLeave={someOtherHandler}
    >
      {/* {renderOverlay} */}
      <div className={classes.media}>
        {official.hasOwnProperty("photoUrl") ?
        <img className={classes.media} src={official.photoUrl} alt={official.name}/> :
        renderPlaceholder()
      }
      </div>
      <Typography align="left" className={classes.heading}>{official.name}</Typography>
      {office.hasOwnProperty("name") &&
        <Typography align="left" className={classes.subHeader}>{office.name}</Typography>
      }
      <Button 
        variant="contained" 
        style={{     
          width: '100%',
          height: 50,
          backgroundColor: '#465078',
          color: '#ffffff',
          borderRadius: 0,
          position: 'absolute',                  
          bottom: 0,                 
          left: 0 }}
        onClick={() => selectRep(official, office)}>
          Contact
      </Button>
    </div>
  );
}

export default (RepCard)