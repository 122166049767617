import React, {Component} from "react";
import { connect } from "react-redux";

// import Avatar from "@material-ui/core/Avatar";
// import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
// import Box from "@material-ui/core/Box";
// import SearchIcon from '@material-ui/icons/Search';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
// import Chip from '@material-ui/core/Chip';
import Hidden from '@material-ui/core/Hidden';
// import { classnames } from '../helpers';
import USAMap from "react-usa-map";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './App.css'

// import PlacesAutocomplete from "react-places-autocomplete";

// import { Redirect } from 'react-router-dom'

import { initReps, getReps } from "../actions/reps";
// import RepCard from "./RepCard";
import { withRouter } from 'react-router-dom'

class States extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  componentDidMount() {

  }

  mapHandler = (event) => {
    this.setState({selectedState: event.target.dataset.name});
  };

  useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        },
    paper: {
        backgroundColor: 'primary',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    demo_search_bar_container: {
      width: "90%",
      maxWidth: "500px",
      margin: "40px auto 0",
    },
    location_search_input: {
      boxShadow: "0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08)",
      border: "honeydew",
      display: "block",
      width: "100%",
      padding: "16px",
      fontSize: "16px",
      borderRadius: "2px",
      outline: "none"
    },
    aboutSection: {
      minHeight: '100vh',
      position: 'relative',
      backgroundColor: '#DCDCDC',
      zIndex: 1,
      '&:before': {
        backgroundColor: 'inherit',
        top: 0,
        content: '',
        display: 'block',
        height: '50%',
        left: 0,
        position: 'absolute',
        right: 0,
        transform: 'skewY(-1.5deg)',
        transformOrigin: '100%',
        zIndex: -1,
      }
      // clipPath: 'polygon(0 0, 0% 0, 100% 96%, 0 100%)'
    },
    videoTag: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0
    }
  }));

  initialState() {
    return {
        open: false,
        selectedState: ''
    };
  }

  viewRepDetails = (official, office) => {
    this.setState({
      selectedOffice: office,
      selectedOfficial: official,
    })
    this.props.history.push({
      pathname: "/info",
      state: { 
        office: office,
        official: official
      }
    })
  }

handleOpen = (event) => {
    this.setState({
        selectedState: event.target.dataset.name,
        open: true,
        })
  };

handleClose = () => {
    this.setState({open: false})
  };

  render() {
    const { open, selectedState } = this.state;
    const handleClose = this.handleClose;
    const handleOpen = this.handleOpen;
    // const classes = this.useStyles()
    // const submit = this.submitForm;
    // const renderReps = this.renderReps;
    // const handleClick = this.handleClick;

      return  (
        <>
        <Container maxWidth={'md'} component="main">
        <CssBaseline />
        <Hidden smDown>
            <div style={{paddingTop: 50}}>
                <USAMap onClick={handleOpen} />
            </div>
        </Hidden>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={"modal"}
            style={{outline: 'none'}}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            disableAutoFocus={true}
            disableEnforceFocus={true}
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Fade in={open}>
            <Container maxWidth={'md'} style={{minHeight:'100vh', backgroundColor: '#EEEEEE'}}>
                <Typography>State: {selectedState}</Typography>
            </Container>
            </Fade>
        </Modal>
         </Container>
        <div 
            style={{
            marginTop: '8%',
            paddingBottom: 130,
            color: '#ffffff',
            backgroundColor: '#10273C',
            clipPath: 'polygon(0 10%, 100% 0, 100% 100%, 0 100%)'}}
            >
            <Container maxWidth={'lg'} style={{marginTop: 100, paddingTop: 100}}>
            <Typography component='h2' variant='h4'>How To Use</Typography>
            <Grid container spacing={3} style={{marginTop: 50}}>
                <Grid item xs={12} md={4}>
                <div>
                    <img style={{width: 80, height: 80, objectFit: 'cover'}} src={require("../assets/addressHome.svg")} alt="Enter your address"/>
                    <Typography component='h3' variant='h6'>Enter your address</Typography>
                    <p>Based on your location, different individuals represent you on a local, state, and federal level.</p>
                </div>
                </Grid>
                <Grid item xs={12} md={4}>
                <div>
                <img style={{width: 80, height: 80, objectFit: 'cover'}} src={require("../assets/view.svg")} alt="View your representatives"/>
                <Typography component='h3' variant='h6'>View your representatives</Typography>
                <p>We'll show you results from the top down, starting with the President of the United States.</p>
                </div>
                </Grid>
                <Grid item xs={12} md={4}>
                <div>
                <img style={{width: 80, height: 80, objectFit: 'cover'}} src={require("../assets/send.svg")} alt="Get in touch"/>
                <Typography component='h3' variant='h6'>Get in touch</Typography>
                <p>We'll show you all the contact options available for your selected representative. Get your voice heard!</p>
                </div>
                </Grid>
            </Grid>
            </Container>         
        </div>
      </>
      )
  }
}

const mapStateToProps = (state) => ({
  reps: state.reps,
});

export default withRouter(connect(mapStateToProps, {
  getReps,
  initReps
})(States));
