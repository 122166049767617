import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
    typography: {
        fontFamily: ['Work Sans', "sans-serif"].join(",")
    },
    palette: {
        primary: {
            main: "#10273C",
            dark: "#474b4f"
        },
        secondary: {
            main: "#474b4f"
        },
    }
});
